@media (max-width: $mobile-max) {
  body {
    min-width: 360px;
  }
  .site {
    &-phone {
      margin: 0 0 0 auto;
      &__txt {
        display: none;
      }
    }
    &-hamburger {
      display: inline-flex;
    }
  }
  .nav {
    &-main {
      opacity: 0;
      visibility: hidden;
      background: var(--athens-gray);
      transition: all var(--primary-transition);
      transform: translateY(-15px);
      box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      padding: 10px 0;
      margin: 0;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 1;
      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
      &__list {
        width: 100%;
        align-items: stretch;
        flex-flow: column nowrap;
        gap: 0;
      }
      &__links {
        text-align: center;
        padding: 10px var(--gutter);
      }
    }
    &-bottom {
      padding: 24px 0 58px 0;
      &__list {
        width: 100%;
        align-items: stretch;
        flex-flow: column nowrap;
        gap: 0;
      }
      &__links {
        padding: 6px 0;
      }
    }
  }
  .header {
    .btn {
      display: none;
    }
  }
  .section {
    padding: 36px 0;
    &--hero {
      padding: 10px 0 0 0;
    }
    &--brands {
      padding: 36px 0;
    }
  }
  .fw-block {
    margin-top: -36px;
  }
  .hero {
    &-text {
      width: 100%;
      padding: 0 0 30px 0;
    }
    &-image {
      width: 100%;
    }
  }
  .tabs {
    &-wrap {
      padding: 40px 0 30px 0;
    }
    &-panels {
      margin: 10px 0 30px 0;
      &:first-child {
        margin-top: 0;
      }
    }
    &-nav {
      display: none;
      &__links {
        &--mobile {
          display: block;
        }
      }
    }
  }
  .advantages {
    &-wrap {
      grid-template-columns: 1fr;
      gap: 32px;
    }
  }
  .info {
    &-text {
      width: 100%;
      padding: 0 0 30px 0;
    }
    &-image {
      width: 100%;
    }
  }
  .order {
    &-form {
      width: 100%;
      padding: 0 0 30px 0;
    }
    &-text {
      width: 100%;
    }
  }
  .footer {
    text-align: center;
    padding: 40px 0 28px 0;
    .main {
      justify-content: center;
    }
    &__copy {
      width: 100%;
      padding: 0 0 10px 0;
    }
  }
  .size-images, .before-after-images {
    .half {
      width: 100%;
      h6 {
        font: 600 14px/24px var(--primary-font);
      }
    }
  }
}
