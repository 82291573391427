// imports top
@import "config";
@import "normalize";
@import "reset";
@import "typografy";
@import "form";
@import "modal";
@import "select2.min";
@import "swiper-bundle.min";
@import "plugins";

body,
html {
  height: 100%;
}

body {
  color: var(--text-color);
  font: 16px/24px var(--primary-font);

  &.no-scroll {
    overflow: hidden;
  }
}

.wrapper {
  min-height: 100%;
  flex-flow: column nowrap;
  display: flex;
}

.main {
  width: 100%;
  max-width: 1246px;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  margin: 0 auto;

  &--small {
    max-width: 798px;
  }

  &--med {
    max-width: 1054px;
  }

  &--wide {
    max-width: 1404px;
  }

  &--flex {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
}

.site {
  &-phone {
    color: var(--secondary-text-color);
    font: 600 16px/24px var(--primary-font);
    text-decoration: none;
    transition: color var(--primary-transition);
    margin: 0 30px 0 0;
    align-items: center;
    display: inline-flex;
    gap: 8px;

    &:hover {
      color: var(--deco-color);
    }
  }

  &-hamburger {
    height: 45px;
    margin: 0 0 0 20px;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity;
    position: relative;
    align-items: center;
    display: none;
    z-index: 3;

    &__box {
      width: 29px;
      height: 19px;
      position: relative;
      display: block;
    }

    &__inner {
      top: 50%;
      margin-top: -2px;
      display: block;
    }

    &__inner,
    &__inner:before,
    &__inner:after {
      width: 29px;
      height: 2px;
      background: var(--deco-color);
      position: absolute;
      transition: transform .15s ease;
    }

    &__inner:before,
    &__inner:after {
      content: "";
      display: block;
    }

    &__inner:before {
      top: -8px;
    }

    &__inner:after {
      top: -16px;
    }

    &--collapse &__inner {
      top: auto;
      bottom: 0;
      transition: transform .13s cubic-bezier(.55, .055, .675, .19) .13s, background var(--primary-transition);

      &:after {
        transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear, background var(--primary-transition);
      }

      &:before {
        transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19), background var(--primary-transition);
      }
    }

    &--collapse.active &__inner,
    a.active &--collapse &__inner {
      transform: translate3d(0, -7px, 0) rotate(-45deg);
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);

      &:after {
        top: 0;
        opacity: 0;
        transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear;
      }

      &:before {
        top: 0;
        transform: rotate(-90deg);
        transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1);
      }
    }
  }

  &-label {
    color: var(--secondary-deco-color);
    font: 500 14px/20px var(--primary-font);
    background: var(--hint-of-green);
    mix-blend-mode: multiply;
    border-radius: 16px;
    padding: 4px 20px;
    margin: 0 0 16px 0;
    display: inline-block;

    &__txt {
      color: var(--secondary-text-color);
      background: var(--elements-color);
      border-radius: 16px;
      padding: 2px 10px;
      margin: 0 0 0 -16px;
      display: inline-block;
    }
  }

  &-rating {
    height: 20px;
    background: url(../img/icon-star.svg) repeat-x center center;
    display: inline-block;

    &--1 {
      width: 20px;
    }

    &--2 {
      width: 40px;
    }

    &--3 {
      width: 60px;
    }

    &--4 {
      width: 80px;
    }

    &--5 {
      width: 100px;
    }
  }
}

.nav {
  &-main {
    margin: 0 auto 0 40px;

    &__list {
      align-items: center;
      display: inline-flex;
      gap: 32px;
    }

    &__links {
      color: var(--secondary-text-color);
      font: 600 16px/24px var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      display: block;

      &.active,
      &:hover {
        color: var(--deco-color);
      }
    }
  }

  &-bottom {
    padding: 24px 0 58px 0;

    &__list {
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 32px;
    }

    &__links {
      color: var(--athens-gray);
      font: 600 16px/24px var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      display: block;

      &:hover {
        color: var(--deco-color);
      }
    }
  }
}

.header {
  background: var(--athens-gray);
  border-bottom: 1px solid var(--catskill-white);
  padding: 18px 0;
  position: relative;
}

.body {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
}

.section {
  padding: 90px 0;

  &--hero {
    background: var(--athens-gray);
    padding: 50px 0 0 0;
  }

  &--brands {
    background: var(--porcelain);
    padding: 56px 0;
  }

  &--advantages {
    background: var(--athens-gray);
  }

  &--reviews {
    background: var(--frosted-mint);
  }
}

.hero {
  &-text {
    width: 34.935%;
    padding: 0 0 70px 0;
    margin: 0 auto 0 0;
  }

  &-image {
    width: 62.810%;
  }
}

.tabs {
  &-panels {
    text-align: center;
    margin: 40px 0 80px 0;
    display: none;

    &.open {
      display: block;
    }

    img {
      max-width: 100%;
    }
  }

  &-nav {
    padding: 0 0 75px 0;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    display: grid;

    &__links {
      color: var(--text-color);
      text-align: center;
      text-decoration: none;
      transition: all var(--primary-transition);
      border-top: 4px solid var(--catskill-white);
      padding: 20px 20px 0 20px;
      display: block;

      &--mobile {
        display: none;
      }

      &.active {
        border-color: var(--deco-color);

        h5 {
          color: var(--deco-color);
        }
      }
    }
  }
}

.advantages {
  &-wrap {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 64px;

    &__col {
      p {
        padding-bottom: 32px;
      }

      & > :last-child {
        padding-bottom: 0;
      }
    }
  }
}

.info {
  &-text {
    width: 47.369%;
    margin: 0 auto 0 0;

    &__buttons {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }

  &-image {
    width: 47.369%;
  }
}

.slider {
  &-reviews {
    text-align: center;
    padding: 0 0 44px 0 !important;

    &__person {
      border-radius: 50%;
      margin: 10px 0 17px 0;
      display: inline-block;
    }

    .swiper-pagination {
      bottom: 0;
      align-items: center;
      justify-content: center;
      display: flex;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        margin: 0 8px;

        &.swiper-pagination-bullet-active {
          background: var(--deco-color);
        }
      }
    }
  }

  &-brands {
    padding: 16px 0 0 0;

    .swiper-wrapper {
      align-items: center !important;
    }

    &__items {
      height: auto;
      padding: 0 20px;

      img {
        max-width: 100%;
      }
    }
  }
}

.order {
  &-form {
    width: 42.106%;
    margin: 0 auto 0 0;
  }

  &-text {
    width: 42.106%;
  }
}

.footer {
  color: var(--mischka);
  background: var(--fiord);
  padding: 64px 0 48px 0;

  &__copy {
    margin: 0 auto 0 0;
  }

  a:not([class]) {
    color: var(--mischka);
  }
}

.form_errors {
  color: var(--error-color);
  font-size: 0.8em;
}

.hidden {
  display: none !important;
}

.dot:before {
  content: "\A";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--text-color);
  display: inline-block;
  vertical-align: middle;
  margin: -2px 12px 0 0;
}

.square {
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;

  &.bg-grey {
    background-color: var(--porcelain);
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    border: solid 1px var(--text-color);
  }
}

.half {
  width: 50%;
}

.size-images, .before-after-images {
  .half {
    margin-bottom: 50px;
  }

  h6 {
    color: var(--secondary-text-color);
    padding: 20px 0 0 0;
  }
  img {
    max-width: 90%;
  }
}

.fw-block {
  padding: 60px 0 0;
  margin: -90px 0 40px;
}

.bg-hint-of-green {
  background: var(--hint-of-green);
}

// imports bottom
@import "tablet";
@import "mobile";
@import "helpers";
