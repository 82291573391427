.form {
  &-regular {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 18px 20px;
    &__items {
      width: 100%;
      &--half {
        width: calc(50% - 10px);
      }
      &--grid {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        gap: 16px 20px;
      }
    }
    &__label {
      color: var(--secondary-text-color);
      font: 14px/20px var(--primary-font);
      padding: 0 0 6px 0;
      display: block;
    }
  }
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])),
textarea {
  width: 100%;
  color: var(--text-color);
  font: 16px var(--primary-font);
  transition: border var(--primary-transition);
  box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
  border: 1px solid var(--border-color);
  border-radius: var(--primary-radius);
  padding: 11px 14px;
}
textarea {
  height: 99px;
  resize: none;
  display: block;
  &[rows] {
    height: auto;
  }
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]))::placeholder,
textarea::placeholder {
  color: var(--placeholder-color) !important;
  opacity: 1;
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])):focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}
input.error:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])),
textarea.error {
  border: 1px solid var(--error-color) !important;
}
input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + label {
    min-height: 20px;
    font: 500 16px/20px var(--primary-font);
    cursor: pointer;
    white-space: pre-wrap;
    padding: 0 0 0 32px;
    position: relative;
    display: inline-block;
    &:before,
    &:after {
      content: "";
      box-sizing: border-box;
      border: 1px solid var(--border-color);
      transition: all var(--primary-transition);
      position: absolute;
      top: 0;
      left: 0;
    }
    &:before {
      width: 20px;
      height: 20px;
    }
    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }
  &:checked {
    & + label {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.error {
    & + label {
      &:before {
        border-color: var(--error-color);
      }
    }
  }
  &:disabled {
    & + label {
      &:before {
        background: var(--border-color);
      }
    }
  }
}
input[type=checkbox] {
  & + label {
    &:before {
      border-radius: 6px;
    }
    &:after {
      width: 20px;
      height: 20px;
      content: url(../img/icon-check.svg);
      border: 1px solid var(--deco-color);
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
    }
  }
}
input[type=radio] {
  & + label {
    &:before {
      border-radius: 50%;
    }
    &:after {
      width: 8px;
      height: 8px;
      background: var(--deco-color);
      border: none;
      border-radius: 50%;
      top: 6px;
      left: 6px;
    }
  }
  &:checked {
    & + label {
      &:before {
        border-color: var(--deco-color);
      }
    }
  }
}
.btn {
  font: 600 16px/24px var(--primary-font);
  text-decoration: none;
  vertical-align: middle;
  transition: all var(--primary-transition);
  border-radius: var(--primary-radius);
  align-items: center;
  justify-content: center;
  display: inline-flex;
  gap: 12px;
  &--regular {
    color: var(--elements-color);
    background: var(--deco-color);
    border: 1px solid var(--deco-color);
    padding: 9px 17px;
    &:hover {
      background: var(--secondary-deco-color);
    }
  }
  &--default {
    color: var(--secondary-text-color);
    background: var(--elements-color);
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
    border: 1px solid var(--border-color);
    padding: 9px 17px;
    &:hover {
      color: var(--deco-color);
    }
  }
  &--med {
    font-size: 18px;
    line-height: 28px;
    padding: 15px 27px;
  }
  &--big {
    padding: 25px 27px;
  }
  &--block {
    width: 100%;
  }
}