.modal {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  transition: all var(--primary-transition);
  padding: var(--gutter);
  overflow: auto;
  position: fixed;
  inset: 0;
  z-index: 5;
  &.open {
    opacity: 1;
    visibility: visible;
  }
  &:before {
    height: 100%;
    content: "";
    vertical-align: middle;
    display: inline-block;
  }
  &__hold {
    width: 100%;
    vertical-align: middle;
    display: inline-block;
    position: relative;
  }
  &__bg {
    content: "";
    background: rgba(52, 64, 84, .7);
    backdrop-filter: blur(8px);
    position: fixed;
    inset: 0;
    z-index: 1;
  }
  &__wrap {
    color: var(--secondary-text-color);
    text-align: left;
    background: var(--elements-color);
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, .08), 0 8px 8px -4px rgba(16, 24, 40, .03);
    border-radius: 12px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    &--order {
      width: 100%;
      max-width: 400px;
    }
  }
  &__icon {
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
    border: 1px solid var(--dawn-pink);
    border-radius: 10px;
    padding: 11px;
    display: inline-flex;
  }
  &__close {
    text-decoration: none;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    position: absolute;
    top: 26px;
    right: 26px;
    &:after {
      content: "";
      position: absolute;
      inset: -5px;
    }
    img {
      transition: transform var(--primary-transition);
      display: inline-block;
    }
    &:hover {
      img {
        transform: rotate(90deg);
      }
    }
  }
  &:has(&__bg:hover) &__close {
    img {
      transform: rotate(90deg);
    }
  }
  &__header {
    padding: 24px 24px 16px 24px;
  }
  &__body {
    padding: 24px;
  }
  &__footer {
    padding: 8px 24px 24px 24px;
    display: flex;
    gap: 12px;
  }
  &__sep {
    border-top: 1px solid var(--border-color);
    margin-left: -24px;
    margin-right: -24px;
    display: block;
    @media (max-width: $mobile-max) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}