h1,
.h1 {
  color: var(--secondary-text-color);
  font: 600 clamp(32px, 4vw, 60px)/clamp(44px, 5vw, 72px) var(--primary-font);
  padding: 0 0 24px 0;
}
h2,
.h2 {
  color: var(--secondary-text-color);
  font: 600 clamp(32px, 4vw, 48px)/clamp(44px, 5vw, 60px) var(--primary-font);
  padding: 0 0 20px 0;
}
h3,
.h3 {
  color: var(--secondary-text-color);
  font: 600 clamp(32px, 4vw, 36px)/44px var(--primary-font);
  padding: 0 0 20px 0;
}
h4,
.h4 {
  color: var(--secondary-text-color);
  font: 500 clamp(24px, 4vw, 32px)/clamp(36px, 5vw, 44px) var(--primary-font);
  padding: 0 0 20px 0;
}
h5,
.h5 {
  color: var(--secondary-text-color);
  font: 600 20px/30px var(--primary-font);
  padding: 0 0 20px 0;
}
h6,
.h6 {
  color: var(--ebony);
  font: 600 18px/28px var(--primary-font);
  padding: 0 0 20px 0;
}
ul,
ol {
  &:not([class]) {
    font: 16px/24px var(--primary-font);
    padding: 0 0 20px 0;
    li {
      &:before {
        display: inline-block;
      }
    }
  }
}
ul {
  &:not([class]) {
    li {
      &:before {
        width: 5px;
        height: 5px;
        content: "";
        background: var(--text-color);
        border-radius: 50%;
        margin: 0 8px 0 0;
        position: relative;
        top: -2px;
      }
    }
  }
}
ol {
  &:not([class]) {
    counter-reset: li;
    li {
      &:before {
        font-weight: bold;
        content: counter(li) ".";
        counter-increment: li;
        margin: 0 5px 0 0;
      }
    }
  }
}
p {
  font: 16px/24px var(--primary-font);
  padding: 0 0 20px 0;
  &.small {
    font-size: 14px;
    line-height: 20px;
  }
  &.med {
    font-size: 18px;
    line-height: 28px;
  }
  &.big {
    font-size: 20px;
    line-height: 30px;
  }
}
a {
  &:not([class]) {
    color: var(--deco-color);
    text-decoration: none;
    transition: color var(--primary-transition);
    &:hover {
      color: var(--secondary-deco-color);
      text-decoration: underline;
    }
  }
  &[href^="mailto:"] {
    word-break: break-all;
  }
  &:focus-visible {
    outline: 1px solid var(--deco-color);
  }
}
.table-responsive {
  overflow-x: auto;
  margin: 0 0 20px 0;
  clear: both;
}
table {
  &:not([class]) {
    width: 100%;
    border-bottom: 1px solid var(--dawn-pink);
    th {
      color: var(--secondary-text-color);
      font: bold 12px/18px var(--primary-font);
      text-align: left;
      background: var(--hint-of-green);
      border-left: 2px solid var(--elements-color);
      padding: 13px 24px;
      @media (max-width: $mobile-max) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    td {
      font: 14px/20px var(--primary-font);
      border-left: 2px solid var(--elements-color);
      border-top: 1px solid var(--dawn-pink);
      padding: 14px 24px;
      @media (max-width: $mobile-max) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    th,
    td {
      &:first-child {
        border-left: none;
      }
    }
    tr {
      &:nth-of-type(even) {
        td {
          background: var(--athens-gray);
        }
      }
    }
  }
}
.text-color {
  color: var(--text-color) !important;
}
.text-color-deco {
  color: var(--deco-color) !important;
}
.text-color-error {
  color: var(--error-color) !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-normal {
  font-weight: normal !important;
}
@media (max-width: $mobile-max) {
  .hidden-m {
    display: none !important;
  }
}
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .hidden-t {
    display: none !important;
  }
}
@media (min-width: $desktop-min) {
  .hidden-d {
    display: none !important;
  }
}
