@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .site {
    &-phone {
      margin: 0;
      &__txt {
        display: none;
      }
    }
  }
  .header {
    .btn {
      display: none;
    }
  }
  .hero {
    &-text {
      width: 42.935%;
    }
    &-image {
      width: 54.810%;
    }
  }
  .advantages {
    &-wrap {
      gap: 32px;
    }
  }
  .order {
    &-form {
      width: 47%;
    }
    &-text {
      width: 47%;
    }
  }
  .size-images, .before-after-images {
    .half {
      width: 100%;
    }
  }
}
