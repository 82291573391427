.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .p-t-#{$i} {
      padding-top: #{$i}px !important;
    }
  }
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .p-l-#{$i} {
      padding-left: #{$i}px !important;
    }
  }
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .p-r-#{$i} {
      padding-right: #{$i}px !important;
    }
  }
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .p-b-#{$i} {
      padding-bottom: #{$i}px !important;
    }
  }
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .m-t-#{$i} {
      margin-top: #{$i}px !important;
    }
  }
}
.m-t-auto {
  margin-top: auto !important;
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .m-l-#{$i} {
      margin-left: #{$i}px !important;
    }
  }
}
.m-l-auto {
  margin-left: auto !important;
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .m-r-#{$i} {
      margin-right: #{$i}px !important;
    }
  }
}
.m-r-auto {
  margin-right: auto !important;
}
@for $i from $padding-margin-from through $padding-margin-to {
  @if ($i) % $padding-margin-step==0 {
    .m-b-#{$i} {
      margin-bottom: #{$i}px !important;
    }
  }
}
.m-b-auto {
  margin-bottom: auto !important;
}
@media (max-width: $mobile-max) {
  .hidden-m {
    display: none !important;
  }
}
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .hidden-t {
    display: none !important;
  }
}
@media (min-width: $desktop-min) {
  .hidden-d {
    display: none !important;
  }
}
.clearfix:after,
.clear {
  height: 0;
  content: "";
  clear: both;
  display: table;
}
.visually-hidden {
  position: absolute;
  top: auto;
  left: -99999px;
}