/* media queries scss */
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1279px;
$desktop-min: 1280px;

/* loop variables scss */
$padding-margin-from: 0;
$padding-margin-to: 30;
$padding-margin-step: 2;

:root {
  /* colors with hex values */
  --dove-gray: #737373;
  --white: #fff;
  --athens-gray: #F9FAFB;
  --dawn-pink: #EAECF0;
  --catskill-white: #F2F4F7;
  --fiord: #475467;
  --ebony: #101828;
  --mischka: #D0D5DD;
  --porcelain: #F3F4F5;
  --silver: #B8B8B8;
  --hint-of-green: #EAFFF3;
  --frosted-mint: #DCFEEB;
  --mountain-meadow: #17C15F;
  --green-haze: #07A84B;
  --monza: #d60017;

  /* reassign color vars to semantic color scheme */
  --text-color: var(--dove-gray);
  --secondary-text-color: var(--fiord);
  --elements-color: var(--white);
  --deco-color: var(--mountain-meadow);
  --secondary-deco-color: var(--green-haze);

  /* font */
  --primary-font: Inter, sans-serif;

  /* other elelemnts */
  --primary-transition: .2s linear;
  --placeholder-color: var(--silver);
  --border-color: var(--mischka);
  --primary-radius: 8px;
  --error-color: var(--monza);
  --gutter: 15px;

  /* media queries css */
  --mobile-max: #{$mobile-max};
  --tablet-min: #{$tablet-min};
  --tablet-max: #{$tablet-max};
  --desktop-min: #{$desktop-min};
}