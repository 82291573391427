// custom select
.select2-container {
  .select2-selection--single {
    height: auto;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
    border: 1px solid var(--border-color);
    border-radius: var(--primary-radius);
    .select2-selection__rendered {
      color: var(--text-color);
      font: 16px/42px var(--primary-font);
      padding-left: 14px;
      padding-right: 30px;
    }
    .select2-selection__arrow {
      width: 30px;
      height: auto;
      bottom: 1px;
    }
  }
  .select2-dropdown {
    border-color: var(--border-color);
    .select2-results__option {
      padding: 6px 14px;
      &.select2-results__option--highlighted {
        background: var(--deco-color);
      }
    }
  }
}